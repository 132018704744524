import Retool from 'react-retool';

function App() {
  return (
    <div class="app">
      <Retool url="https://primocare.retool.com/embedded/public/d261c74b-53cb-4a88-9d14-64df974ca98b"/>
    </div>
  );
}

export default App;